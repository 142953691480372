<template>
  <view-item title="数据充值">
    <en-card class="h-full" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <div :style="{ height: `${height - 55}px` }">
            <div class="h-full">
              <p>当前剩余数据查询次数:{{ count.value }}</p>
              <en-form :model="form.data" :rules="form.rules">
                <div style="float: left;color:red">*充值金额：</div>
                <en-form-item prop="topUpMoney" style="width: 270px">
                  <select-maintain
                    v-model="form.data"
                    :ajax="{
                      action: 'GET /enocloud/query/vehicle/topup/combo',
                      params: (value) => ({ name: value })
                    }"
                    :props="{ label: 'count', value: '' }"
                    value-key="id"
                    class="w-full"
                    ><template #default="{ option }: { option: EnocloudQueryDefinitions['LiyangComboDto'] }">
                      <div class="flex justify-between gap-6">
                        <span>{{ option.count }}次</span>
                        <span class="text-neutral-500">{{ formatMoney(option.price) }}</span>
                      </div>
                    </template>
                  </select-maintain>
                </en-form-item>
                <div style="margin-left: 300px; margin-top: -35px">支付方式：</div>
                <en-form-item>
                  <en-input value="微信支付" disabled style="width: 200px; margin-left: 400px; margin-top: -29px"></en-input>
                </en-form-item>
                <en-form-item style="margin-left: 620px; margin-top: -40px">
                  <en-button type="primary" @click="form.pay.click()" :disabled="form.disabled">立即支付</en-button>
                </en-form-item>
              </en-form>

              <p>充值记录</p>
              <en-table :data="table.data" :method="table.get" pagination :paging="table.paging" :loading="table.loading">
                <en-table-column label="订单时间">
                  <template #default="{ row }: { row: EnocloudQueryDefinitions['LiyangTopupDto'] }">
                    {{ formatDate(row.preparedDatetime) }}
                  </template>
                </en-table-column>
                <en-table-column label="订单编号" prop="serialNo"></en-table-column>
                <en-table-column label="充值金额" prop="price">
                  <template #default="{ row }: { row: EnocloudQueryDefinitions['LiyangTopupDto'] }">
                    {{ formatMoney(row.price) }}
                  </template>
                </en-table-column>
                <en-table-column label="充值人" prop="preparedBy"></en-table-column>
                <en-table-column label="支付方式" prop="source.message"></en-table-column>
              </en-table>
            </div>
          </div>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>
<script lang="ts">
import { EnMessageBox } from '@enocloud/components'
import { h } from 'vue'
export default factory({
  config: {
    children: {
      form: {
        data: {
          id: '',
          count: '',
          price: ''
        },
        ajax: {
          post: {
            action: 'POST /enocloud/query/vehicle/topup',
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.price.length === 0 && this.form.data.count.length === 0
          }
        },

        pay: {
          async click() {
            if (!this.form.data.price) {
              EnMessageBox.confirm( '充值金额还未选','提示')
              return false
            }
            const polling = () =>
              setTimeout(() => {
                this.detail.get().then((res) => {
                  const { status } = res.data[0]
                  if (status?.code === 'PD') {
                    this.table.get()
                    this.count.get().then((res) => (this.count.value = res.data[0]))
                  } else {
                    polling()
                  }
                })
              }, 500)

            this.form.post().then((res) => {
              this.form.data.id = res.data[0]
              this.native.get().then((res) => {
                setTimeout(polling, 5000)
              })
            })
          }
        }
      },
      detail: {
        data: {
          id: '',
          status: {
            code: ''
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/query/vehicle/topup/:topupId',
            data: 'object',
            loading: true,
            params(params) {
              params.paths = [this.detail.data.id]
            }
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/query/vehicle/topup',
            data: 'array',
            loading: true,
            pagination: true
          }
        }
      },
      count: {
        value: '',
        ajax: {
          get: {
            action: 'GET /enocloud/query/vehicle/count',
            data: 'array',
            loading: true,
            pagination: true
          }
        }
      },
      native: {
        ajax: {
          get: {
            action: 'POST /enocloud/query/vehicle/topup/:topupId/payment/wechat',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        }
      }
    }
  },
  async mounted() {
    this.table.get()
    this.count.get().then((res) => (this.count.value = res.data[0]))
  }
})
</script>
